import EtoloDuplicates from './EtoloDuplicates.svelte';
import LearningSpeed from './LearningSpeed.svelte';
import ListCycleServices from './ListCycleServices.svelte';
import OneSession from './OneSession.svelte';

document.addEventListener('DOMContentLoaded', function(event) {

	const apps = [
					{target: "#list-cycleservices", class: ListCycleServices, props: {}, debugJsondata: false, removeInnerHTML: true},
					{target: "#one-session", class: OneSession, props: {}, debugJsondata: false, removeInnerHTML: true},
					{target: "#learning-speed-app", class: LearningSpeed, props: {}, debugJsondata: false, removeInnerHTML: true},
					{target: "#etolo-duplicates", class: EtoloDuplicates, props: {}, debugJsondata: false, removeInnerHTML: true},
				];

	// get user, if any
	let appuser = document.querySelector('body').getAttribute('data-appuser');
	let userroles = document.querySelector('body').getAttribute('data-userroles').split(' ');
	const csrftoken = document.querySelector("body").dataset.csrftoken;


	let instances = apps.reduce( (accumulator, app) => {

		let target = document.querySelector(app.target);
		let instance = false;
		let message_html = '';

		if(target)
		{
			// read JSON from HTML
			let jsondatael = target.querySelector('.jsondata');
			if(jsondatael) app.props.jsondata = JSON.parse(jsondatael.textContent);
			if(jsondatael && app?.debugJsondata) console.log(app.props.jsondata, {t: jsondatael.textContent});

			// empty existing HTML
			if(app?.removeInnerHTML) {

				// save message, if any
				message_html = target.querySelector('.columbus_message')?.innerHTML;

				target.innerHTML = '';
			}

			if(message_html && message_html.length > 0) {
				app.props = {...app.props, message_html};
			}

			if(appuser && appuser.length > 0) {
				app.props = {...app.props, appuser};
			}

			if(userroles && userroles.length > 0) {
				app.props = {...app.props, userroles};
			}
			if(csrftoken) {
				app.props = {...app.props, csrftoken};
			}

			instance = new app.class({
				target: target,
				props: app.props,
			});
		}

		if(instance) accumulator.push( instance );

		return accumulator;
	}, []);
});
