
/**
 * @param {string} objectclass
 * @param {string} id
 * 
 */
async function getObject(objectclass = '', id = '', graph = null, fn = null) {
	const protocol = 'https:'; //window.location.protocol;
	const hostname = window.location.hostname;
	let port = window.location.port;
	if(port != 443) port = `:${port}`;
	const url = new URL(`${protocol}//${hostname}${port}/v2016/`);
	url.searchParams.set('voir', 'api-objects');
	url.searchParams.set('class', objectclass);
	url.searchParams.set('id', id);
	if(graph) {
		url.searchParams.set('graph', graph);
	}
	if(fn) {
		url.searchParams.set('function', fn);
	}
	
	const options = {
		method: 'GET',
		mode: 'cors',
		headers: {
			"Content-Type": "application/json",
		},
	};
	const response = await fetch(url, options);
	const r = await response.json();

	return r?.data;
}

export { getObject };