<script>
	import ListCycleServices_service from "./ListCycleServices_service.svelte";
	import CycleService from "./classes/CycleService";
	import { readCSRFtoken } from "./utils/csrf";

	export let jsondata = {};
	export let message_html = "";
	let cycle = jsondata?.cycle ?? {};
	let services = [];
	let missingHours = 0;

	let totalServicedHours = 0;

	const addNewService = () => {
		
		let newService = {
			id: 'new' + Math.random(),
			type: 0,
			audienceid: 0,
			quantity: missingHours,
			comments: "",
			cycleid: cycle.id,
			trainerid: 0,
			unit: 24, // hour by default
		};
		cycle.services.push(newService);

		cycle = cycle;
	};

	$: {
		if(cycle) {
			services = cycle.services ?? [];
			services = services.map( (s) => {
				return Object.assign( new CycleService(), s );
			})
		}
	}
	$: {
		totalServicedHours = services.reduce((acc, s) => {
			acc += s.quantity;
			return acc;
		}, 0);

		// round this
		totalServicedHours = Math.round(totalServicedHours * 100) / 100;
	}

	$:{
		missingHours = cycle.Duree - totalServicedHours;

		if( Math.abs(missingHours * 100) < Math.abs(cycle.Duree) ) {
			missingHours = 0;
		}

	}
</script>

<style>
</style>

{#if message_html}{message_html}{/if}

<h1>Services for {cycle.ReferenceAccent}</h1>

<form action="/v2016/?voir=list-cycleservices" method="post">
	<input
		name="CSRFtoken"
		value="{readCSRFtoken()}"
		type="hidden"
	/>

	<p>
		Edit Client <a href="/v2016/?voir=one-client&id={cycle.client.id}"
			>{cycle.client.personne.surnom}&nbsp;»</a
		>
		| Edit Cycle
		<a href="/v2016/?voir=one-cycle&id={cycle.id}"
			>{cycle.ReferenceAccent}&nbsp;»</a
		>&nbsp;
	</p>

	<h2>Cycle Services data</h2>
	<p><label for="">Total foreseen hours</label> {cycle.Duree}</p>
	<p>
		<label for="">Total Serviced</label>
		{totalServicedHours}
		{#if missingHours == 0 }
			— OK
		{:else if totalServicedHours < cycle.Duree}
			— missing some time ({cycle.Duree - totalServicedHours})
			<button on:click|preventDefault={() => addNewService()}
				>Create Service for missing hours</button
			>
		{:else if totalServicedHours > cycle.Duree}
			— too much given! Please adapt timings in Services ({totalServicedHours -
				cycle.Duree})
		{/if}
	</p>
	<p><label for="">Work Progress</label> {cycle.WorkProgressPercentage}</p>

	<h2>Services we do</h2>

	<input type="hidden" name="actiononservices" value="update" />
	<input type="hidden" name="clientid" value="" />
	<input type="hidden" name="cycleid" value={cycle.id} />

	<table class="sortableTable tablesorter">
		<thead>
			<tr class="tablesorter-headerRow">
				<th>Description &amp; Audience</th>
				<th>Unit</th>
				<th>Quantity</th>
				<th>Rate</th>
				<th>Trainer</th>
				<th />
			</tr>
		</thead>
		<tbody>
			{#each services as service (service.id) }
				<ListCycleServices_service bind:cycle bind:service />
				{:else}
				<tr>
					<td colspan="6">No services yet</td>
				</tr>
			{/each}
		</tbody>
	</table>

	<p><input type="button" value="Add new Service" on:click|preventDefault={ () => addNewService() }> <input type="submit" value="Save all Services" /> </p>
</form>
