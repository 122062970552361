<script>

	let cycles = [];
	let persons = [];

	async function getDuplicatesFromAPI(table = '') {
		const protocol = window.location.protocol;
		const hostname = window.location.hostname;
		const url = new URL(`${protocol}//${hostname}/v2016`);
		url.searchParams.set('voir', 'api_etoloduplicates');
		url.searchParams.set('table', table);
		
		const options = {
			method: 'GET',
			mode: 'cors',
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await fetch(url, options);

		return await response.json();
	}
	
	async function getDuplicates(e) 
	{
		cycles = await getDuplicatesFromAPI('cycle');
		persons = await getDuplicatesFromAPI('person');

		return [cycles, persons];
	}

</script>

<style>

	.list-of-duplicates {
		display: grid;
		grid-template-columns: 16em 3em 3em 9em;
	}

	.name {
		overflow: hidden;
      	white-space: nowrap;
	}

	.name:hover {
		overflow:visible;
		background-color: white;
	}

</style>

<h2 use:getDuplicates>Etolo Duplicates</h2>

<h3>Cycles</h3>	
<div id="duplicated_cycles" class="list-of-duplicates">
	{#each cycles as cycle}
			<span class="name">{cycle.name}</span>
			<span><a href="https://columbus.atolo.eu/v2016/?giveme=one-cycle&id={cycle.EUid}">EU</a></span>
			<span><a href="https://columbus.atolo.ch/v2016/?giveme=one-cycle&id={cycle.CHid}">CH</a></span>
			<span>{cycle.action ?? 'Choose Action…'}</span>
	{:else}
	No duplicated Cycle…
	{/each}

</div>

<h3>Persons</h3>	
<div id="duplicated_persons" class="list-of-duplicates">
	{#each persons as p}
		
		<span class="name">{p.name}</span>
		<span><a href="https://columbus.atolo.eu/v2016/?giveme=one-person&id={p.EUid}">EU</a></span>
		<span><a href="https://columbus.atolo.ch/v2016/?giveme=one-person&id={p.CHid}">CH</a></span>
		<span>{p.action ?? 'Choose Action…'}</span>
	{:else}
	No duplicated Person…
	{/each}

</div>
