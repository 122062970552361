<script>
	// @ts-check
	import { onMount } from "svelte";
	import { getObject } from "./utils/api-helper.js";
	import { readQuantityFromInput } from "./utils/time-helper.js";
	import { readCSRFtoken } from "./utils/csrf.js";

	import sessionexample from "./session-example.json";
	import Session from "./classes/Session.js";
	import Duration from "./classes/Duration.js";
	import OneSession_oneStudent from "./OneSession_oneStudent.svelte";
	import CycleService from "./classes/CycleService.js";

	const cyclegraph =
		"{client{personne}, CycleLocations{location{address}}, students{personne, presences}, courses{quantity, presences, CycleService{shortName}, trainer{personne}, StatusAsText}, services{description, sessions, trainer{personne}, shortName, longName, textualRemaining}, trainers{personne, trainerFromDB}, trainersInCycle{tarif1}, WorkProgressPercentage, WorkProgressFraction, DurationAsText, TotalDurationOfServices, GivenCoursesDuration, RemainingDuration, -programmeXml }";

	/** @type {Session} session */
	export let session = null;
	let cycle = null;
	let students = [];
	let studentsforsession = [];
	/** @type {(Number|null)} */
	let cycleserviceid = null;
	let cycleservice = null;
	let displayedcycleservices = [];
	let displayTrainerNamesInServices = true;

	let quantityinput = "";
	let sessionDurationForDisplay = "";
	let sessionDurationForStudents = "";
	let travelallowance = undefined;

	let serviceRemainingDuration = null;

	let adaptStudentsToSession = false;

	let csrftoken = readCSRFtoken();

	let debug = false;

	export let appuser = null;
	export let userroles = null;

	function doLoadSession(e) {
		loadSession();
	}

	async function loadSession() {
		const url = new URL(window.location.href);
		const id = url.searchParams.get("id");

		let session0 = {};
		if (id && id != "new") {
			if (url.hostname == "localhost") {
				session0 = sessionexample.data;
			} else {
				const graph = `{ cycle${cyclegraph}, quantity, trainer, locationPrice, CycleService{shortName}, presences}`;
				session0 = await getObject("Colu_Object_Cours", id, graph);
			}
		} else {
			const today = new Date().toISOString().substring(0, 10);
			session0 = { CycleID: Number(url.searchParams.get("CycleID")), isNew: true, Statut: 2, Date: today }; // given by default
		}

		/** @type Session session */
		session = Object.assign(new Session(), session0);

		if(cycleserviceid != session?.cycleserviceid) cycleserviceid = session?.cycleserviceid;

		if (session?.CycleService?.unit == 24) {
			const d = new Duration();
			if (session?.quantity > 300) {
				d.createFromSeconds(session.quantity);
			} else {
				d.createFromCoursDuree(session.Duree);
			}
			quantityinput = d.format("%h:%m");
		} else {
			quantityinput = (session?.quantity ?? 0).toString();
		}

		if(session.isNew) setTravelAllowance();

		session = session;

		await loadCycle();

		return session;
	}

	async function loadCycle() {
		
		if (session?.CycleID)
			cycle = await getObject(
				"Colu_Object_Cycle",
				session.CycleID,
				cyclegraph
			);

		cycle.TotalDurationOfServices = Object.assign(
			new Duration(),
			cycle?.TotalDurationOfServices ?? {}
		);

		cycle.GivenCoursesDuration = Object.assign(
			new Duration(),
			cycle?.GivenCoursesDuration ?? {}
		);

		cycle.RemainingDuration = Object.assign(
			new Duration(),
			cycle?.RemainingDuration ?? {}
		);

		cycle.courses = cycle?.courses.map((s) => {
			const session_o = new Session();
			Object.assign(session_o, s);
			return session_o;
		});

		cycle.services.map((s) => {
			s.cycle = cycle;
			return Object.assign(new CycleService(), s);
		});

		displayTrainerNamesInServices = !(
			Array.isArray(cycle?.trainers) && cycle.trainers.length <= 1
		);

		displayedcycleservices =
			cycle?.services.filter((s) => s.trainerid > 0) ?? [];

		if (userroles.length == 1 && userroles.includes("trainer")) {
			displayedcycleservices = displayedcycleservices.filter(
				(s) => s.trainerid == appuser
			);
		}

		displayedcycleservices = displayedcycleservices.map((s) => {
			let o = new CycleService();
			Object.assign(o, s);
			return o;
		});

		return cycle;
	}

	function setTravelAllowance() {

		// find travelcost associated to this trainer & location
		const tic = cycle?.trainersInCycle?.find( tic0 => { return tic0.FormateurID == session.formateur; });
		const tc_o = JSON.parse(tic?.travelcosts ?? '[]');
		const ta0 = tc_o[session.Lieu] ?? null;

		if(ta0 === null) {
			let cyclelocation = cycle?.CycleLocations.find((l) => l.locationid == session.Lieu);
			travelallowance = cyclelocation?.location?.TravelAllowance;
		}
		else {
			travelallowance = ta0;
		}

		session.transportcost = travelallowance;
	}

	function getAddressDisplayName(address) {
		return `${address.voie} · ${address.zip} ${address.city}`; 
	}

	$: {
		if (session?.Duree) {
			checkIfCycleIsFinished();
		}
	}

	$: {
		if(cycleserviceid) {
			cycleservice = cycle?.services.find((s) => s.id == cycleserviceid) ?? null;
		}

		if (!cycleservice && cycle?.services.length == 1) {
			cycleservice = cycle.services[0];
		}

		if (cycleservice) {
			if(cycleserviceid != cycleservice.id ) cycleserviceid = cycleservice.id;
			cycleservice = Object.assign(new CycleService(), cycleservice);
			session.unit = cycleservice.getUnitAsString();
			session.formateur = cycleservice.trainerid;
		} else {
			if (cycle) {
				//console.log("no cycleservice for session");
			}
		}
	}

	$: {
		students = cycle?.students ?? [];
	}

	$: {
		if (cycleservice && cycleservice.audienceid) {
			studentsforsession = students.filter(
				(s) => s.id == cycleservice.audienceid
			);
		} else {
			studentsforsession = students;
		}
	}

	$: {
		// calculate remaining time for this service
		if (cycleservice) {
			serviceRemainingDuration = cycleservice.getRemainingDuration();
		}
	}

	$: {
		if (quantityinput || cycleservice || adaptStudentsToSession || cycleserviceid) {
			let {quantity, duration} = readQuantityFromInput(quantityinput, cycleservice?.unit);

			session.quantity = quantity;
			session = session;

			sessionDurationForDisplay = duration.toString() ?? '';

			if(session.isNew) setTimeout( copyDurationToStudents, 1);
		}
	}

	function copyDurationToStudents()
	{
		if(session.isCancelled())
		{
			sessionDurationForStudents = '0';
		}
		else
		{
			sessionDurationForStudents = quantityinput;
		}
	}

	function checkIfCycleIsFinished() {
		if (!cycle) return;

		// check if total time has been reached
		const remaining = cycle.RemainingDuration;

		let diff = remaining.asSeconds() - session.Duree;

		if (diff <= 0) {
			alert(
				"Cycle seems to be finished, please make sure End Date is correct before saving. "
			);
		}
	}

</script>

<h1 use:doLoadSession>Editing Session for {cycle?.ReferenceAccent || "…"}</h1>

{#if session && cycle}
	<form action="/v2016/?voir=one-session" method="POST">
		<input type="hidden" name="voir" value="one-session" />
		<input type="hidden" name="id" value={session.id} />
		<input type="hidden" name="CycleID" value={cycle.id} />
		<input type="hidden" name="redirectAfterSave" value="/v2016/?voir=one-cycle&id={cycle.id}" />
		<input type="hidden" name="redirectAfterDelete" value="/v2016/?voir=one-cycle&id={cycle.id}" />
		<input name="CSRFtoken" value="{csrftoken}" type="hidden">
		<div class="form2columns">
			<label for="">Cycle</label>
			<span>
				<a href="/v2016/?voir=one-cycle&id={session?.CycleID}"
					>{cycle?.ReferenceAccent}</a
				>&nbsp;:
				<span id="cycle_totaltime"
					>{cycle?.TotalDurationOfServices} to give,
				</span>
				<span id="cycle_giventime"
					>{cycle?.GivenCoursesDuration} given =
				</span>
				{#if cycle?.RemainingDuration.asSeconds()}{cycle?.RemainingDuration}
					remaining{:else}it's all done!{/if}
			</span>
			<label for="">Schedule</label><span>{cycle?.Horaire}</span>
			<label for="">Cycle Service</label>
			<span>
				{#if session.isNew}
					{#each displayedcycleservices as service}
						{@const inputid = `service_${service.id}`}
						{@const disabled = (service?.textualRemaining == 'all done') }
						<input
							type="radio"
							name="cycleserviceid"
							value={service.id}
							bind:group={cycleserviceid}
							id={inputid}
							{disabled}
							/>
						<label for={inputid} class="labelForService">
							{#if displayTrainerNamesInServices}{service.trainer.personne.surnom}:{/if}
							{service.description}: {service?.textualRemaining}
							{#if service.trainerid == appuser 
								|| userroles.includes("superadmin") 
								|| userroles.includes("respeda") 
								|| userroles.includes("sales")}
								 @ {service.rate}€{/if}
						</label>
						<a href="/v2016/?voir=one-cycleservice&id={service.id}">…</a><br />
					{/each}
				{:else}
					{cycleservice?.longName}
				{/if}
			</span>
			{#if cycleserviceid}
				<label for="date">Date</label>
				<span
					><input
						type="date"
						name="Date"
						id="Date"
						bind:value={session.Date}
					/></span
				>
				<label for="quantityinput">Length</label>
				<span
					><input
						type="text"
						name="quantityinput"
						id="quantityinput"
						autocomplete="off"
						bind:value={quantityinput}
					/>
					{session.unit ?? ""}
					{#if debug}{session?.quantity ?? ''}{/if}
					{#if sessionDurationForDisplay}, understood as {sessionDurationForDisplay}{/if}
				</span>
				<input
					type="hidden"
					name="quantity"
					value={session?.quantity ?? 0}
				/>
				<input
					type="hidden"
					name="Duree"
					value={session?.Duree ?? null}
				/>
				<label for="Statut">Status</label>
				<select id="Statut" name="Statut" bind:value={session.Statut} on:change={ () => adaptStudentsToSession = true }>
					{#each session.getSessionStatusesArray() as status}
						<option value={status.key}>{status.value}</option>
					{/each}
				</select>
			{/if}
		</div>

		{#if cycleserviceid}
			<h3>Students</h3>

			<div class="form2columns">
				{#each studentsforsession as student}
					<OneSession_oneStudent {session} {student} {sessionDurationForStudents} />
				{/each}
			</div>

			<h3>Etc</h3>
			<div class="form2columns">
				<label for="Lieu">Location</label>
				<span>
					<select id="Lieu" name="Lieu" bind:value={session.Lieu}
						on:change={ (e) => setTravelAllowance() }	
					>
						{#if cycle?.CycleLocations.length > 1}
							<option value="0">Please choose Location…</option>
						{/if}
						{#each cycle?.CycleLocations ?? [] as cyclelocation}
							<option value={cyclelocation?.locationid}>
								{cyclelocation?.location?.NomLieu} {cyclelocation?.location?.Adresse ?? getAddressDisplayName(cyclelocation?.location?.address) ?? `CycleLocation ${cyclelocation.id}`}
							</option>
						{/each}
					</select>
					{#if travelallowance !== undefined } Location TC = usually {travelallowance} €{/if}
				</span>
				<label for="transportcost">Transport amount</label><span
					><input
						name="transportcost"
						id="transportcost"
						bind:value={session.transportcost}
					/></span
				>
				<label for="MatiereVue">Details, if any</label><span
					><textarea
						id="MatiereVue"
						name="MatiereVue"
						placeholder="This may of course stay empty"
						bind:value={session.MatiereVue}
					/></span
				>
			</div>

			<div class="form2columns">
				<label for="save" /><span><input type="submit" name="save" value="Save" /></span>
				<label for="delete" /><span><input type="submit" name="delete" value="Delete" /></span>
			</div>
		{/if}
	</form>

	<h2>All Sessions for {cycle?.ReferenceAccent}</h2>
	<table class="sortableTable">
		<thead>
			<tr
				><th>Date</th><th>Length</th><th>Status</th><th>Trainer</th><th
					>Service</th
				></tr
			>
		</thead>
		<tbody>
			{#each cycle?.courses ?? [] as oneCourse}
				<tr class:cancelled={oneCourse.Statut == 3}>
					<td
						><a href="/v2016/?voir=one-session&id={oneCourse.id}"
							>{oneCourse.Date}</a
						></td
					>
					<td>{oneCourse?.getDuration()}</td>
					<td>{oneCourse?.StatusAsText}</td>
					<td>{oneCourse?.trainer?.surnom}</td>
					<td>{oneCourse?.CycleService?.shortName}</td>
				</tr>
			{/each}
		</tbody>
	</table>
{/if}

<style>
	.form2columns {
		display: grid;
		grid-template-columns: 12em 1fr;
		row-gap: 0.5ex;

		text-align: left;
	}

	.labelForService {
		width: unset;
	}
</style>
