<script>
	import { head_selector } from "svelte/internal";

	export let from = 0;
	export let to = 1;
	export let count = 5;

	const height = 24;

	let x1 = 0;
	let x2 = 0;

	let y1 = height / 2;
	let y2 = height / 2;

	let pixelsPerLevel = 20;

	let radius = 2.5;
	let leftpadding = 10;

	let negative = false;

	let axes = ( () => {
		let axes = [];
		for(let i = 0; i < 27; i++) {
			const x = leftpadding + i * pixelsPerLevel;
			axes.push(x);
		}
		return axes;
	})();

	$: {
		x1 = leftpadding + pixelsPerLevel * from;
		x2 = leftpadding + pixelsPerLevel * to;

		negative = from > to;
	}
</script>

<svg
	xmlns:svg="http://www.w3.org/2000/svg"
	xmlns="http://www.w3.org/2000/svg"
	version="1.0"
	x="0"
	y="0"
	width="580"
	height="{height}"
	title="{from} - {to}"
>
	<g>
		{#each axes as x}
		<line
			x1={x}
			y1="0"
			x2={x}
			y2={height}
			class="axis"
		/>
		{/each}
	</g>
	<g>
		<line
			{x1}
			{y1}
			{x2}
			{y2}
			style="stroke-width:{count};"
			class:negative
		/>
		<circle cx={x1} cy={y1} r={radius} class:negative />
		<circle cx={x2} cy={y2} r={radius} class:negative />
	</g>

	<style>
		g {
			background-color: white;
		}
		line {
			stroke: green;
			stroke-width: 5;
			stroke-linecap: round;
		}
		circle {
			stroke: green;
			fill: white;
			stroke-width: 2.5;
		}
		line.axis {
			stroke: hsla(0, 0%, 75%, 0.25);
			stroke-width: 1;
			stroke-linecap: square;
		}
		.negative {
			stroke: grey;
		}
	</style>
</svg>
