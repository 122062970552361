import Duration from '../classes/Duration.js';

/**
 * getSecondsFromTimeString
 *
 * @param   {string}  time_s  [time_s description]
 *
 * @return  {number} seconds
 */
function getSecondsFromTimeString(time_s) {
	time_s = time_s.trim();

	let totalSeconds = 0;
	let hours = 0;
	let minutes = 0;
	let seconds = 0;

	if (time_s === '') {
		totalSeconds = 0;
		
	} else if (/^\d?\d?\d:?$/.test(time_s)) {
		hours = parseInt(time_s); // Hours

	} else if (/^\d?\d?\d:\d\d$/.test(time_s)) {
		const [h, m] = time_s.split(':');
		hours = parseInt(h);
		minutes = parseInt(m);

	} else if (/^\d?\d?\dh\d\d$/.test(time_s)) {
		time_s = time_s.replace('h', ':') + ':00';
		const [h, m] = time_s.split(':');
		hours = parseInt(h);
		minutes = parseInt(m);

	} else if (/^\d?\d?\dh$/.test(time_s)) {
		const h = time_s.replace('h', '');
		hours = parseInt(h);

	} else if (/^\d?\d?\d[\.]\d*$/.test(time_s)) {
		hours = Number.parseFloat(time_s);

	} else if (/^\d?\d?\d:\d\d:\d\d*/.test(time_s)) {
		const [h, m, s] = time_s.split(':');
		hours = parseInt(h);
		minutes = parseInt(m);
		seconds = parseInt(s);

	} else {
		console.log( `Time format was not recognized in input '${time_s}'`);
		totalSeconds = 0;
	}

	totalSeconds = hours * 3600 + minutes * 60 + seconds; // Hours, Minutes, and Seconds
	totalSeconds = Math.round(totalSeconds);

	return totalSeconds;
}



/**
 * @argument {number} totalseconds_i
 * @argument {number} format_i
 */
function getReadableDurationFromSeconds(totalseconds_i = 0, format_i = 0) {

	const format1 = "3 hours 2 minutes 1 second";
	const format2 = "3h02m01s" ?? "3h02";
	const format3 = "3:02:01" ?? "3:02";

	let seconds_i = totalseconds_i;
	let minutes_i = Math.floor(totalseconds_i / 60);
	let hours_i = Math.floor(totalseconds_i / 60 / 60);
	minutes_i = minutes_i - (hours_i * 60);

	let t = '';
	let hours_t = '';
	let minutes_t = '';
	let seconds_t = '';

	if (hours_i > 1) {
		hours_t = `${hours_i} hours`;
	}
	else if (hours_i == 1) {
		hours_t = '1 hour';
	}
	else {
		// no change
	}

	if (minutes_i > 1) {
		minutes_t = `${minutes_i} minutes`;
	}
	else if (minutes_i == 1) {
		minutes_t = '1 minute';
	}
	else {
		// no change
	}

	if (seconds_i > 1) {
		seconds_t = `${seconds_i} seconds`;
	}
	else if (minutes_i == 1) {
		seconds_t = '1 second';
	}
	else {
		// no change
	}

	switch (format_i) {
		case 1:
			t = `${hours_t} ${minutes_t} ${seconds_t}`;
			t = t.replace('  ', ' ');
			t = t.trim();
			break;

		case 2:
			t = `${hours_i}h${pad(minutes_i, 2)}`;
			break;

		case 3:
			t = `${hours_i}:${pad(minutes_i, 2)}`;
			break;

		default:
			t = `Error: no Duration format provided ${format_i}`;
			break;
	}

	return t;


}

function readQuantityFromInput(someinput, unit) {

	let quantity = null
	let duration = null;
	let errormsg = null;

	const d = new Duration();

	if (unit == 24 || unit == 'hours' || unit == 'hour') {
		const seconds = getSecondsFromTimeString(someinput);
		d.createFromSeconds(seconds);

		quantity = seconds;
		duration = d.format();

	} else {
		quantity = Number(someinput);
		let d2 = d.createFromInputAndUnit(someinput, unit);
		duration = d.toString();
	}

	return {quantity, duration};
}

function pad(num, size) {
	let num_s = num.toString();
	while (num_s.length < size) num_s = "0" + num_s;
	return num_s;
}


export { getReadableDurationFromSeconds, getSecondsFromTimeString, readQuantityFromInput };