/**
 * Determines the appropriate text to use based on the value of a number.
 *
 * @param {number} counter - The number to evaluate.
 * @param {string} text0 - The text to use when counter equals 0.
 * @param {string} text1 - The text to use when counter equals 1.
 * @param {string} textN - The text to use when counter is greater than 1.
 * @param {string} [textNeg=''] - The text to use when counter is less than 0. Defaults to an empty string.
 * @param {Formatting_function} formatting_fn - The formatting function to apply to the number. Defaults to an empty function.
 * @returns {string} The appropriate text based on the value of counter.
 * 
 * @typedef {function(number): string} Formatting_function
 */
function string_01N(
	counter,
	text0 = "0",
	text1 = "1",
	textN = "{i}",
	textNeg = "-{i}",
	formatting_fn = (n) => n
) {
	let t = "";

	if (typeof counter !== "number") counter = parseInt(counter);

	// decide which string to use
	if (counter === 0) {
		t = text0;
	} else if (counter === 1) {
		t = text1;
	} else if (counter > 0) {
		t = textN;
	} else if (counter < 0 && textNeg !== "") {
		t = textN;
	} else {
		t = textNeg;
	}

	// handling format 
	const number_s = formatting_fn(counter);

	// replace the number placeholder with its value
	t = t.replace(/\{\i\}/gi, number_s);

	return t;
}

export  {string_01N};