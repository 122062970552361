<script>
  import { onMount } from "svelte";
  import { readQuantityFromInput } from "./utils/time-helper";
  import Duration from "./classes/Duration.js";


	export let session = {};
	export let student = {};
	export let sessionDurationForStudents = '';

	let presence = {};

	let inputcontrol = null;
	let quantityinput = '';
	let allowcopy = true;
	let errormsg = '';

	let debug = false;

	$:{
		if(session) {
			presence = session?.presences?.find(p => p.StagiaireID == student.StagiaireID) ?? presence;
		}
	}

	$:{
		if(sessionDurationForStudents && session && session.isNew && allowcopy) {
			quantityinput = sessionDurationForStudents;
			quantityinputChange();
		}
	}

	onMount(async () => {
		updateQuantityinput();
	})

	function updateQuantityinput() {

		if (session?.unit == 'hours') {
			const d = new Duration();
			d.createFromSeconds(presence.quantity);
			quantityinput = d.format("%h:%m");
		} else {
			quantityinput = (presence?.quantity ?? 0).toString();
		}

	}

	function quantityinputChange() {

		let {quantity, duration} = readQuantityFromInput(quantityinput, session?.unit);

		console.log("osos", quantity,session?.unit );

		if(quantity > session.quantity) {
			errormsg = `That is more than the session's duration, please edit`;
			inputcontrol.focus();
			inputcontrol.select();

		}
		else
		{
			errormsg = '';
			presence.quantity = quantity;
			presence = presence;
		}
	}

	$: if(quantityinput) quantityinputChange();

</script>

<style>
	.error {
		color: red;
	}
</style>


<span>{student?.personne?.surnom}</span>
<span>
	<input name="presence_student_input[{student.StagiaireID}]" 
		autocomplete="off"
		bind:value={quantityinput} 
		bind:this={inputcontrol} 
		on:blur={ () => allowcopy = false }
		> {session.unit ?? ''}
	<input name="presence_student[{student.StagiaireID}]" value={presence?.quantity ?? 0} type="hidden">
	<span class="error">{errormsg}</span>
	{#if debug}{presence?.quantity ?? ''}{/if}
</span>