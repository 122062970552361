<script>
	import { onMount } from "svelte";
	import { getObject } from "./utils/api-helper.js";

	import LearningSpeedsegment from "./LearningSpeedsegment.svelte";

	
	const query = `
SELECT es.id, es.idStagiaire, langue, es.Date, niveau
FROM evaluationdestagiaire es
JOIN (
  SELECT idStagiaire
  FROM evaluationdestagiaire
  WHERE evaluationdestagiaire.Date >= '2022-01-01' 
  GROUP BY idStagiaire
  HAVING COUNT(*) > 1
) u ON es.idStagiaire = u.idStagiaire

WHERE es.Date >= '2022-01-01' 
	AND niveau <> ''
	AND es.Langue = 112

ORDER BY es.idStagiaire ASC, es.Langue, es.Date
`;


const scales = [
		"A0(0)",
		"A0(25)",
		"A0(50)",
		"A0(75)",
		"A1(0)",
		"A1(25)",
		"A1(50)",
		"A1(75)",
		"A2(0)",
		"A2(25)",
		"A2(50)",
		"A2(75)",
		"B1(0)",
		"B1(25)",
		"B1(50)",
		"B1(75)",
		"B2(0)",
		"B2(25)",
		"B2(50)",
		"B2(75)",
		"C1(0)",
		"C1(25)",
		"C1(50)",
		"C1(75)",
		"C2(0)",
		"C2(25)",
		"C2(50)",
		"C2(75)",
	];

	let lang = '';
	let from = '';
	let minlevel = scales[0];
	let maxlevel = scales[scales.length - 1];
	
	let cycledurationForClient = 32;
	
	const levels = JSON.parse(document.getElementById('jsondata').innerText);

	let progresses = [];

	/**
	 * function to create all progresses
	*/
	async function createProgresses() {

		progresses = [];

		let previous = levels[0];

		for(const level of levels.slice(1)) {

			let levelok = true;
			if(previous.niveau < minlevel || previous.niveau > maxlevel) {
				levelok = false;
			}
			
			if(levelok && level.idStagiaire == previous.idStagiaire && level.lang == previous.lang && level.Date >= from) {

				// this is a progress
				const levelDiff = scales.indexOf(level.niveau) - scales.indexOf(previous.niveau);

				// get the cycle which will start soon after this level
				//let level0 = await getObject('Colu_Object_Level', previous.id, '{cycleDuration}');
				//console.log('cycle', level0?.getNextCycle);

				const hours = previous?.NextCycleDuration ?? 0;

				if(hours == 0) {
					console.log('hours', hours);
				}
				else
				{
					const progress = {
						idStagiaire: level.idStagiaire,
						lang: level.Langue,
						date: level.Date,
						initialLevel: previous.niveau,
						reachedLevel: level.niveau,
						// plus number of hours received to measure speed
						levelDiff,
						learningSpeed: levelDiff / hours,
					};
					progresses.push(progress);
				}

			} 

			// save this for next round
			previous = level;
		}

		// order progresses by initialLevel and reachedLevel
		progresses.sort((a, b) => {
			if(a.initialLevel < b.initialLevel) return -1;
			if(a.initialLevel > b.initialLevel) return 1;
			if(a.reachedLevel < b.reachedLevel) return -1;
			if(a.reachedLevel > b.reachedLevel) return 1;
			return 0;
		});

		console.log(`Extracted ${progresses.length} progresses from ${levels.length} levels`);

		progresses = progresses;

	}

	let summaries = [];

	/**
	 * summary of progresses
	*/
	function getSummaryOfProgresses() {

		summaries = progresses.reduce( (acc, progress) => {

			const key = `${progress.initialLevel} &rarr; ${progress.reachedLevel}`;

			if(!acc[key]) {
				acc[key] = {
					initialLevel: progress.initialLevel,
					reachedLevel: progress.reachedLevel,
					levelDiff: progress.levelDiff,
					count: 0
				};
			}

			acc[key].count++;

			return acc;

		}, []);
	}

	let medianSpeed = 0;
	/**
	 * get median progress
	*/
	function getMedianSpeed() {

		const property = 'learningSpeed';

		const values = progresses.map(obj => obj[property]);
		values.sort((a, b) => a - b);

		const length = values.length;
		if (length % 2 === 1) {
			const medianIndex = (length - 1) / 2;
			medianSpeed = values[medianIndex];
		} else {
			const middleIndex = length / 2;
			const middleValues = [values[middleIndex - 1], values[middleIndex]];
			medianSpeed = (middleValues[0] + middleValues[1]) / 2;
		}

		medianSpeed = medianSpeed;
	}

	let averageSpeed = 0;
	/**
	 * get average speed
	*/
	function getAverageSpeed() {
		
		const property = 'learningSpeed';

		const sums = progresses.reduce( (acc, p) => {

			acc.totalDiff += p[property];
			acc.count++;
			return acc;
		}, 
		{totalDiff: 0, count: 0});

		averageSpeed = (sums.count > 0) ? sums.totalDiff / sums.count : 0;
	}

	onMount(async () => {
		
		await updateNumbers();

		const url = new URL(window.location.href);
		lang = url.searchParams.get("lang") ?? '105';
		from = url.searchParams.get("from") ?? '2022-01-01';
	});

	async function updateNumbers() {
		document.getElementById('jsondata').style.display = 'none';
		await createProgresses();
		getSummaryOfProgresses();
		getMedianSpeed();
		getAverageSpeed();
	}

	$:{
		if(minlevel || maxlevel) {
			updateNumbers();
		}
	}

</script>

<style>

	.progressSummaries {
		display: grid;
		grid-template-columns: repeat(3, 3em) 4em 8em;
		grid-gap: 0;

		text-align: center;
	}

	.progressSummaries span {
		height: 24px;
	}

	.tableheader {
		font-weight: bold;
	}

</style>

<h2>Select</h2>
<form action="">
	<input type="hidden" name="voir" value="learning-speed">
	<label for="lang">Lang</label>
	<select name="lang" id="lang" bind:value={lang}>
		<option value="105">NL</option>
		<option value="125">FR</option>
		<option value="112">EN</option>
	</select><br />

	<label for="from">From</label>
	<input type="date" name="from" id="from" bind:value={from}><br />

	<label> </label><button type="submit">Search</button><br />

</form>

<h2>Filter</h2>

<label for="minlevel">Minimum start level</label>
<select name="minlevel" id="minlevel" bind:value={minlevel}>
	{#each scales as s}
	<option value="{s}">{s}</option>
	{/each}
</select><br />	

<label for="maxlevel">Maximum start level</label>
<select name="maxlevel" id="maxlevel" bind:value={maxlevel}>
	{#each scales as s}
	<option value="{s}">{s}</option>
	{/each}
</select><br />

<h2>Summary </h2>
<ul>
	<li>{progresses.length} Deelnemers</li>
	<li>Median speed = { (medianSpeed * cycledurationForClient).toFixed(2) } Levels per {cycledurationForClient} hours</li>
	<li>Average speed = { (averageSpeed * cycledurationForClient).toFixed(2) } Levels per {cycledurationForClient} hours</li>
</ul>

<section class="progressSummaries">
	<span class="tableheader">From</span><span class="tableheader">&rarr;</span><span class="tableheader">To</span><span class="tableheader">Count</span><span class="tableheader"></span>
	{#each Object.values(summaries) as summary}
		<span>{summary.initialLevel}</span>
		<span>&rarr;</span>
		<span>{summary.reachedLevel}</span>
		<span>{summary.count}</span>
		<span class="chart" 
			title="{summary.initialLevel} - {summary.reachedLevel}">
			<LearningSpeedsegment 
			from={ scales.findIndex( s => s == summary.initialLevel)}
			to={ scales.findIndex( s => s == summary.reachedLevel)}
			count={summary.count}
			/>
		</span>
	{/each}
</section>


<h2>Details</h2>
{#each progresses as progress}
	<p>{progress.initialLevel} &rarr; {progress.reachedLevel} by <a href="/v2016/?voir=one-trainee&id={progress.idStagiaire}">Trainee {progress.idStagiaire}</a></p>
{/each}

